.scan-option-label{
  font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--gray-500);
    margin: 0;
    padding: 20px;
}

.align-item-center{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}

.header-bodypoints {
  color: white;
  font-size: 20px;
  font-weight: 600;
  margin-top: 16px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  z-index: 1000;
  pointer-events: none;
  transition: opacity 0.5s ease;
}

.modal.open {
  opacity: 1;
  pointer-events: all;
}

.modal-content {
  width: 1280px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
}

.blue-box {
  width: 100%;
  height: 100%;
  background-color: blue;
}

.close .modal-content {
  transform: scale(0.5);
}

.start-scan-cards-container {
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	width: 100%;
	margin-top: 2vh;
	gap: 1vh;
}

.start-scan-card-background {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	margin-left: 2vw;
	margin-right: 2vw;
	flex-direction: column;
	grid-column: span 1 / span 1;
	gap: 1rem;
	justify-content: flex-end;
	align-items: center;
	border-radius: 0.75rem;
	background-color: #ffffff;
	box-shadow:
		0 4px 6px -1px rgba(0, 0, 0, 0.1),
		0 2px 4px -1px rgba(0, 0, 0, 0.06);
	height: 50vh;
}

.start-scan-card-content-container {
	width: 100%;
	background: linear-gradient(
		rgba(255, 255, 255, 0),
		rgb(0, 0, 0, 0.7),
		rgb(0, 0, 0)
	);
	display: flex;
	padding: 1.25rem;
	flex-direction: column;
	gap: 1.25rem;
	justify-content: center;
	align-items: center;
	border-bottom-right-radius: 0.75rem;
	border-bottom-left-radius: 0.75rem;
}

.start-scan-card-content {
	font-size: 1.125rem;
	line-height: 1.75rem; 
	text-align: center; 
	color: #ffffff; 
}

.start-scan-dashboard-btn {
	background-color: #53389e;
	color: white;
	font-weight: 700;
	font-size: 18px;
	border: none;
	padding: 10px 20px;
	border-radius: 8px;
	cursor: pointer;
	transition: background 0.3s ease-in-out;
}
#root,
div.ant-modal-wrap .ant-modal-content .ant-modal-body {
	.ant-table-row-expand-icon {
		color: #6739b7;
	}

	.slider-gradient .ant-slider-track {
		background: linear-gradient(130.28deg, #6941C6 1.45%, #54CDF2 138.49%);
	}

	.slider-gradient .ant-slider-rail {
		background-color: #e0e0e0;
	}

	.slider-gradient .ant-slider-handle {
		border-color: #6941C6;
	}

	.ant-pagination-item-link {
		padding: 4px 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 4px;
		color:var(--text-color)
	}

	.ant-pagination {
		display: flex;
		justify-content: center;
		align-items: center;
		color:var(--text-color)
	}

	.ant-pagination-prev,
	.ant-pagination-next {
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid #d9d9d9;
		border-radius: 5px;
		color:var(--text-color)
	}

	a[rel="nofollow"] {
		color:var(--text-color)
	}
	

	.ant-pagination-item {
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid #d9d9d9;
		margin-bottom: 0;
		border-radius: 5px;
		color:var(--text-color)
	}

	.ant-badge.ant-badge-status .ant-badge-status-dot {
		height: 14px;
		width: 14px;
	}

	.ant-pagination-item-active {
		background-color: #eaecf0;
		border: 1px solid #eaecf0;
	color: var(--mainmenu-bg-color)!important;
	}

	.rom-card-container>.ant-card-small>.ant-card-head {
		padding: 10px;
	}

	.ant-btn.ant-btn-sm {
		height: 24px;
		line-height: 1.57;
		padding: 0 7px;
		border-radius: 4px;
	}

	.ant-btn-default.ant-btn-background-ghost {
		color: #ffffff;
		background: transparent;
		border-color: #ffffff;
		box-shadow: none;
	}

	.ant-btn:disabled {
		color: #cfcfcf;
		border-color: #cfcfcf;
		background-color: #f3f3f3;
	}

	.ant-btn:disabled:hover {
		color: #cfcfcf;
		border-color: #cfcfcf;
		background-color: #f3f3f3;
		cursor: not-allowed;
	}

	.start-session-css {
		background: var(--button-color);
		color: var(--button-text-color);
		border-radius: 50px;
		transition: none;
	}

	.non-finished-survey-css {
		background: var(--button-color);
	}

	.start-session-css:hover,
	.non-finished-survey-css:hover {
		background: var(--button-color);
		background-size: 400% 400%;
		-webkit-animation: BgAnimaiton 18s ease infinite;
		-moz-animation: BgAnimaiton 18s ease infinite;
		animation: BgAnimaiton 18s ease infinite;
		color: rgb(83, 63, 133);
	}

	.pain-button-css {
		background: #eaecf0;
		border: none;
		height: 110px;
		width: 292px;
		border-radius: 10px;
		font-size: 20px;
		color: transparent;
		transition: none;
	}

	.pain-button-css:hover {
		background: linear-gradient(130deg,
				#6941C6,
				#54CDF2,
				#71b0ff,
			);
		background-size: 400% 400%;
		-webkit-animation: BgAnimaiton 18s ease infinite;
		-moz-animation: BgAnimaiton 18s ease infinite;
		animation: BgAnimaiton 18s ease infinite;
		-webkit-text-fill-color: white;
		color: white;
	}

	.pain-button-css:hover .icon-hover-effect {
		filter: brightness(0) invert(1);
	}

	.gradient-text {
		background: var(--default-bg-tab-color);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		// transition: color 0.3s ease, -webkit-text-fill-color 0.3s ease;
	}

	.onboard-list-button-css img {
		filter: none;
		transition: filter 0.3s ease;
	}

	.onboard-list-button-css.selected img {
		filter: brightness(0) invert(1);
	}

	.onboard-list-button-css:hover img {
		filter: brightness(0) invert(1);
	}


	.onboard-list-button-css {
		background-color: #eaecf0;
		width: 95%;
		border-radius: 16px;
		border: none;
		font-size: 20px;
		height: auto;
		min-height: 60px;
		text-align: left;
		display: flex;
		justify-content: start;
		padding: 10px;
		white-space: normal;
		word-wrap: break-word;
		overflow-wrap: break-word;
	}

	.gradient-label {
		background: linear-gradient(130.28deg, #6941C6 1.45%, #54CDF2 138.49%);
		background-clip: text;
	}

	.onboard-list-button-css:hover {
		background: linear-gradient(130deg,
				#6941C6,
				#54CDF2,
				#71b0ff,
			);
		background-size: 400% 400%;
		-webkit-animation: BgAnimaiton 18s ease infinite;
		-moz-animation: BgAnimaiton 18s ease infinite;
		animation: BgAnimaiton 18s ease infinite;
		-webkit-text-fill-color: white;
		color: white;
	}

	.onboard-list-button-css.selected {
		background: linear-gradient(130.28deg, #6941C6 1.45%, #54CDF2 138.49%) !important;
		border: 5px solid #BDFF00 !important;
		color: white !important;
		position: relative;
	}

	.onboard-list-button-css.selected::after {
		content: '✔';
		position: absolute;
		right: -20px;
		color: #5F87DD;
		background: #BDFF00;
		border-radius: 9999px;
		padding-left: 13px;
		padding-right: 13px;
		padding-top: 5px;
		padding-bottom: 5px;
	}

	.selected-item {
		background: #eaecf0;
		border-radius: 10px;
		margin-top: 10px;
		padding: 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		max-width: 400px;
	}

	.selected-item .tick-icon {
		color: #BDFF00;
	}

	.ant-select-selection-placeholder {
		color: #5e5e5e;
	}

	.dashboard-container .ant-card-body {
		padding: 0;
		border-radius: 9px;
		background: #9f62e7;
		overflow: hidden;
	}

	.feature-card .ant-card-body {
		padding: 0;
		border-radius: 9px;
		overflow: hidden;
	}

	.dashboard-container .ant-card.ant-card-bordered {
		width: 33%;
	}

	.feature-card .ant-card.ant-card-bordered {
		width: 80%;
	}

	.ant-btn>span.add-exercise {
		display: flex;
		gap: 4px;
	}

	.ant-btn>span.add-exercise svg {
		position: relative;
		top: 4px;
	}

	.ant-input-group-wrapper:not(.ant-input-compact-last-item).ant-input-compact-first-item .ant-input {
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
	}

	.ant-tabs-top>.ant-tabs-nav {
		margin-bottom: 0;
	}

	.ant-collapse-borderless>.ant-collapse-item:last-child {
		border-bottom: 1px solid #d9d9d9;
	}

	.pt-panel.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-expand-icon {
		height: 50px;
	}

	.summary-panel.ant-collapse>.ant-collapse-item>.ant-collapse-header {
		cursor: pointer;
	}

	.pt-panel.ant-collapse>.ant-collapse-item>.ant-collapse-header {
		padding: 0px 16px;
	}

	.program-panel .ant-collapse-header {
		display: flex;
		align-items: center;
	}

	.summary-panel .ant-collapse-header {
		display: flex;
		align-items: center;
	}

	.survey-panel .ant-collapse-header {
		display: flex;
		align-items: center;
	}

	.ant-collapse-borderless.stream-panel {
		background-color: var(--activity-card-bg-color);
	}

	.stream-panel.ant-collapse>.ant-collapse-item>.ant-collapse-header {
		padding: 8px 16px;
	}

	.as-panel.ant-collapse>.ant-collapse-item>.ant-collapse-header {
		padding: 8px 16px !important;
	}

	.activity-panel.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-expand-icon {
		display: none;
	}

	.survey-panel .ant-collapse-item>.ant-collapse-header .ant-collapse-expand-icon {
		height: 30px;
	}

	.activity-panel.ant-collapse>.ant-collapse-item>.ant-collapse-content .ant-collapse-content-box {
		display: none;
	}

	.profile-tab .ant-tabs-top>.ant-tabs-nav {
		flex-wrap: wrap;
	}


	.ant-popover {
		user-select: none;
	}

	.ant-tooltip {
		user-select: none;
	}

	.ant-message {
		user-select: none;
	}

	.text-white .ant-empty-normal .ant-empty-description {
		color: #fff;
	}

	.ant-modal-root .ant-modal-confirm-body-wrapper .ant-modal-confirm-paragraph {
		max-width: none;
	}

	.ai-assistant-search-bar .ant-select-selector {
		outline: none;
		box-shadow: none;
		background-color: inherit;
		border: none;
	}

	.ai-assistant-search-bar .ant-select-selector .ant-select-selection-search-input,
	.ai-assistant-search-bar .ant-select-selector .ant-select-selection-item {
		color: white;
		font-size: 18px;
	}

	.ai-assistant-search-bar .ant-select-dropdown {
		border: none;
		background-color: rgb(83, 56, 158);
		border-radius: 16px;
		margin-top: 20px;
	}

	.typing {
		max-width: 100%;
		animation: typing 5s linear, .5s step-end infinite alternate;
		white-space: nowrap;
		overflow: hidden;
	}

	@keyframes typing {
		from {
			max-width: 0;
		}

		to {
			max-width: 100%;
		}
	}

	@keyframes blink {
		50% {
			border-color: transparent;
		}
	}

	@keyframes fadeOutUp {
		0% {
			opacity: 1;
			transform: translateY(0);
		}

		100% {
			opacity: 0;
			transform: translateY(-20px);
		}
	}

	.fade-out-up {
		animation: fadeOutUp 1s forwards;
	}

	@keyframes BgAnimation {
		0% {
			background-position: 0% 50%;
		}

		50% {
			background-position: 100% 50%;
		}

		100% {
			background-position: 0% 50%;
		}
	}

	.total-patient-main-div {
		margin: 20px;
		user-select: none;
		color: var(--text-color-root);

		h3 {
			font-size: 1.125rem;
			line-height: 1.75rem;
			font-weight: 600;
			margin-top: 1.25rem;
			margin-bottom: 1.25rem;
			display: flex;
			align-items: center;
		}

		.badge-css {
			margin-right: 8px;
			display: flex;
			align-items: center;
		}
	}

	.total-patient-length {
		margin-left: 12px;
		margin-top: -6px;
	}

	.admin-total-patient-card-container {
		background-color: #f2f4f7;
	}

	.admin-total-patient-card-subcontainer {
		user-select: none;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		padding-top: 0.125rem;
		padding-bottom: 0.125rem;
		border-radius: 0.5rem;
		background-color: #f9fafb;

		.collapse-form {
			background-color: #f9fafb;

			.collapse-header-form {
				background-color: white;
				border-width: 1px;
				border-radius: 0.5rem;
				margin-top: 0.75rem;
				margin-bottom: 0.75rem;
				border-color: #eaecf0;

				.header-first-div {
					display: flex;
					height: 3.5rem;

					.header-primary-div {
						display: flex;
						align-items: center;
						justify-content: center;
						gap: 5px;
					}
				}
			}

			.header-first-subdiv {
				padding: 4px;
			}

			.session-details {
				font-size: 0.75rem;
				line-height: 1rem;
				color: #000000;
				font-weight: 700;
				margin-left: auto;
				padding-right: 20px;
				display: flex;
				align-items: center;
				text-transform: none;
			}
		}

		.empty-conatiner-css {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.new-patient-data {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 5px;
	}

	.admin-total-patient-card-subcontainer .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-expand-icon {
		height: 55px;
	}

	.expanded-row-main-div {
		margin: 0.75rem;
		border-radius: 0.5rem;
		background-color: var(--collapse-bg-color);
		padding-top:12px ;
		padding-left:12px ;
		padding-right:12px ;
		padding-bottom:4px ;


		.expand-sub-div {
			border-radius: 0.5rem;
			background-color: #ebebeb;
			border-color: inherit;
			margin-bottom: 0.75rem;
			cursor: pointer;
			user-select: none;

			.date-div {
				height: 3.5rem;
				display: flex;
				padding: 0.5rem;
				justify-content: space-between;
				align-items: center;

				.date-div-sub-container {
					margin-left: 12px;
					display: flex;
					gap: 20px;
					align-items: center;
				}

				.session-date {
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.score-label-css {
					display: flex;
					height: fit-content;
					align-items: center;
					gap: 4px;
					padding-left: 0.625rem;
					padding-right: 0.625rem;
					padding-top: 0.125rem;
					padding-bottom: 0.25rem;
					border-radius: 9999px;
					background-color: #12b76a;
					color: #ffffff;
				}
			}

			.strapi-exercise-main-div {
				height: 3.5rem;
				display: flex;
				padding: 8px;
				justify-content: space-between;
				align-items: center;
				box-sizing: border-box;

				.strapi-exercise-subcontainer-div {
					display: flex;
					align-items: center;
					gap: 20px;
					margin-left: 12px;
				}

				.strapi-item {
					color: #4a1fb8;
					background-color: #ebe9fe;
					padding: 0.5rem;
					padding-left: 0.5rem;
					padding-right: 8px;
					border-radius: 9999px;
					font-size: 0.75rem;
					line-height: 1rem;
					margin-left: 4px;
					width: 100px;
				}
			}

			.strapi-item-label {
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.strapi-tag-item {
				width: 80px
			}

			.strapi-item-label-div {
				display: flex;
				justify-content: flex-start;
			}
		}
	}

	.dashboard-badge-count-div {
		display: flex;
		align-items: center;
		padding: 8px;
		transition: all 0.3s;
	}

	.dashboard-badge-label-span {
		color: #667085;
		padding-right: 26px;
	}

	.custom-tabs .ant-tabs-tab-active .dashboard-badge-count-div {
		color: var(--text-color);
	}

	.custom-tabs .ant-tabs-tab-active .dashboard-badge-label-span {
		color: var(--text-color);

	}

	.create-report-container {
		margin: 1.5rem;
	}

	.create-report-container .header-container {
		margin-top: 1.25rem;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 9px;
		margin-bottom: 1.5rem;
	}

	.create-report-container .header-container .magic-band-span-css {
		border-radius: 9999px;
		background-color: #f4ebff;
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
		padding-left: 0.375rem;
		padding-right: 0.375rem;
	}

	.header-content-div-css {
		width: 100%;
	}

	.header-content-div-css .ai-assistant-report-title {
		font-weight: 700
	}

	.header-content-div-css .ai-assistant-report-description {
		color: #667085;
	}

	.create-report-modal-container {
		margin-left: 1.25rem;
		margin-right: 2rem;
	}

	.lower-outlet-bottom-css {
		padding-bottom: 40px;
	}

	.collapse-panel-container {
		user-select: none;
		margin-top: 8px;
		border-radius: .5rem;
	}

	.collapse-wrapper {
		padding: 8px 15px 14px 15px;
		border-radius: .5rem;
		background-color: var(--collapse-bg-color);
	}

	.collapse-panel-wrapper {
		user-select: none;
		margin-left: 20px;
		margin-right: 20px;
	}

	.collapse-subpanel-wrap {
		padding: 8px 15px 14px 15px;
		border-radius: .5rem;
		background-color: var(--collapse-bg-color);
	}

	.program-video-recorder-container .program-video-recorder.full-height {
		height: 97vh;
	}

	.custom-progress .ant-progress-outer {
		height: 20px !important;
	}

	.custom-progress .ant-progress-bg {
		height: 20px !important;
		border-radius: 0px !important;
	}

	.custom-progress .ant-progress-inner {
		border-radius: 0px !important;
	}

	.background-color .ant-modal-content {
		background-color: rgb(83, 56, 158);
		border-radius: 0px;
		padding: 0px;
	}

	.div-image-wrapper {
		position: relative;
	}

	.div-image-wrapper::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0);
		border-radius: 0.5rem;
		transition: background-color 0.3s;
	}

	.div-image-wrapper .play-button {
		opacity: 1;
	}

	.rehab-info-wraper .ant-typography {
		color: white;
	}

	.rehab-info-wraper .ant-statistic-title {
		color: rgba(255, 255, 255, 0.5);
	}

	.rehab-info-wraper .ant-statistic-content {
		color: white;
	}

	.exercise-info .ant-drawer-content-wrapper {
		box-shadow: none;
	}

	.ant-checkbox-inner{
		border: 2px solid var(--mainmenu-bg-color);
	}
}

.onboard-profile-form .ant-input-affix-wrapper,
.onboard-profile-form .ant-input-affix-wrapper-focused,
.onboard-profile-form .ant-input-affix-wrapper:hover {
	border-color: transparent;
	box-shadow: none;
	outline: none;
}

.posture .ant-card-small>.ant-card-head {
	padding-left: 0px!important;
	padding-right: 12px!important;
} 
::-webkit-scrollbar {
	height: 12px;
	width: 13px;
}

::-webkit-scrollbar-track {
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	border: 3px solid transparent;
	border-radius: 100px;
	background-clip: content-box;
	background-color: lightgrey;
}

div.ant-space-item span.dropdown-badge .ant-badge .ant-badge-status-dot {
	height: 14px;
	width: 14px;
}

@keyframes zoomInOut {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.1);
	}

	100% {
		transform: scale(1);
	}
}

.zoom-animation {
	animation: zoomInOut 20s ease-in-out infinite;
}
